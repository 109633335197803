import React, { Component } from "react";

import MinecraftMainGreen from "../images/minecraft-main-green.jpg";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";

function fireworks() {
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
  window.confetti(
    Object.assign({}, defaults, {
      particleCount: 50,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    })
  );
  window.confetti(
    Object.assign({}, defaults, {
      particleCount: 50,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    })
  );
}

function copyLink() {
  var copyText = document.getElementById("petition-link");
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
  document.activeElement.blur();
  toast.success("Copied the link!");
}

class index extends Component {
  constructor(props) {
    super(props);
    this.state = { confetti: null };
  }

  componentDidMount() {
    this.setState({
      confetti: setInterval(function () {
        fireworks();
      }, 250),
    });
    setTimeout(() => {
      clearInterval(this.state.confetti);
    }, 2000);
  }

  render() {
    return (
      <main className="flex min-h-screen flex-col">
        <Helmet>
          <title>Thank you! | Brown Esports</title>
          <meta
            name="description"
            content="Gaming and esports at Brown University is massively popular, but is one of the least accessible recreational activities on campus. Sign our petition today!"
          />
          <meta name="theme-color" content="#C00404" />
          <meta
            property="og:title"
            content="Create a Space for Gaming and Esports at Brown University"
          />
          <meta
            property="og:description"
            content="Gaming and esports at Brown University is massively popular, but is one of the least accessible recreational activities on campus. Sign our petition today!"
          />
          <meta property="og:image" content={MinecraftMainGreen} />
          <link
            rel="icon"
            type="image/png"
            href="https://brownesports.org/img/favicon-32x32.png"
            sizes="32x32"
          ></link>
        </Helmet>
        <Toaster position="top-center" reverseOrder={false} />
        <Navbar />

        <div className="px-2 max-w-3xl mx-auto text-center flex-1">
          <h1 className="text-5xl md:text-7xl leading-tight font-bold mb-8 mt-8 md:mt-24">
            <span className="bg-clip-text text-transparent bg-gradient-to-br from-blue-500 to-blue-400">
              Thank you
            </span>{" "}
            for signing our petition!{" "}
            <button className="cursor-pointer" onClick={() => fireworks()}>
              <span role="img" aria-label="confetti">
                🎉
              </span>
            </button>
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 leading-normal mb-6">
            Take a moment to celebrate by spam clicking the confetti emoji{" "}
            <span role="img" aria-label="smiling face">
              😊
            </span>
          </p>
          <p className="text-xl md:text-2xl text-gray-600 leading-normal mb-6">
            We're very grateful for your support. Your help allows us to make
            gaming accessible to all, and provide our teams with the resources
            necessary to excel.
          </p>
          <p className="text-xl md:text-2xl text-gray-600 leading-normal mb-8">
            If you want to help us make a space for gaming and esports at Brown
            a reality, take a moment and help us spread the word about this
            petition.
          </p>

          <div className="flex flex-wrap mb-12">
            <div className="w-full">
              <div className="relative flex items-center max-w-xs mx-auto">
                <input
                  id="petition-link"
                  value="https://petition.brownesports.org"
                  type="text"
                  className="w-full text-gray-800 px-3 py-2 pr-12 text-sm border border-gray-300 rounded focus:outline-none"
                  placeholder="Petition Link"
                />
                <button
                  className="absolute inset-0 left-auto focus:outline-none bg-blue-500 hover:bg-blue-400 transition-colors duration-100 rounded-r-md border-l border-gray-300"
                  aria-label="Copy URL"
                  onClick={() => copyLink()}
                >
                  <span className="text-sm mx-2 text-white font-semibold">
                    Copy URL
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

export default index;
